* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  background-color: #fff5f0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  display: flex;
  justify-content: space-between;
  height: 3rem;
  padding: 1rem;
  padding-bottom: 0;
}

.logo {
  height: 100%;
  object-fit: contain;
}

.icon {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.icon-btn {
  border: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
}

.icon-btn:hover {
  cursor: pointer;
  opacity: 0.7;
}

main {
  flex: 1;
  /* min-height important for flex not to overflow */
  min-height: 0;
  display: flex;
  background-color: #fff5f0;
  flex-direction: column;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.amplify-heading {
  font-weight: bold;
}

.big-card {
  padding: 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 60%;
  min-height: 25rem;
}

.big-card-content {
  flex: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
  overflow: auto;
}

.small-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  height: 40%;
  padding-top: 1rem;
  justify-content: space-between;
}

.small-cards *:nth-child(2) {
  justify-self: center;
}

.small-cards *:nth-child(3) {
  justify-self: end;
}

.small-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  flex: 1;
  max-width: 20rem;
  max-height: 22rem;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 33%;
  min-height: 33%;
}

.small-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height important for flex not to overflow */
  min-height: 0;
}

.small-card-content h1 {
  align-items: center;
  font-size: 7rem;
  color: #ef4270;
  margin: 0;
}

/* Modal */

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  border-radius: 10px;
}

.modal-header {
  display: flex;
  justify-content: end;
  align-items: center;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.modal-content {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media (max-width: 576px) {
  .small-cards {
    grid-template-columns: 1fr;
  }

  .small-card {
    max-width: none;
    height: 250px;
  }

  main {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .big-card {
    height: 25;
  }

  .App {
    height: auto;
  }
}
